/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const Modifiers = {
  filled: (props) => css`
    .input__label {
      background-color: transparent;
      transform: ${props.isFilled ? `translate3d(-0.5rem, -2rem, 0) scale3d(0.875, 0.875, 1)` : ''};
    }

    .state-overlay {
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${props.theme.lowEmphasisBackgroundColor};
    }

    input {
      :hover {
        ~ .state-overlay {
          border-top: 1px solid transparent;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: ${props.theme.hoveredBackgroundColor};
        }
      }

      :focus {
        ~ .state-overlay {
          border-top: 2px solid transparent;
          border-left: 2px solid transparent;
          border-right: 2px solid transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: ${props.theme.focusedBackgroundColor};
        }

        ~ .input__label {
          transform: translate3d(-0.5rem, -2rem, 0) scale3d(0.875, 0.875, 1);
        }
      }
    }
  `,
};

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.defaultSurfaceE00};

  input {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    height: 3rem;
    width: 100%;
    background-color: transparent;
    margin-left: 1rem;
    margin-right: 0.75rem;
    line-height: 1;
    padding-bottom: 0.25rem;
    border: none;

    :hover {
      ~ .state-overlay {
        /* background-color: ${({ theme }) => theme.hoveredBackgroundColor}; */
        border: 1px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColorHover)};
      }
    }

    :focus {
      ~ .input__label {
        transform: translate3d(-0.5rem, -1.5rem, 0) scale3d(0.875, 0.875, 1);
        line-height: 1rem;
      }
      ~ .state-overlay {
        border: 2px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColorHover)};
      }
    }
  }

  .state-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    transition: 0.2s;
    border: 1px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColor)};
  }

  .input__label {
    position: absolute;
    transition: all 0.2s ease;
    padding: 0 0.25rem;
    background-color: ${({ theme }) => theme.defaultSurfaceE00};
    color: ${(props) => (props.error ? props.theme.errorColor : props.theme.onLightColorHighEmphasis)};
    margin-left: 1rem;
    line-height: 1rem;
    transform: ${(props) => (props.isFilled ? 'translate3d(-0.5rem, -1.5rem, 0) scale3d(0.875, 0.875, 1)' : '')};
    pointer-events: none;
  }

  .input__helper-text {
    position: absolute;
    bottom: -1.25rem;
    left: 1rem;
    color: ${(props) => (props.error ? props.theme.errorColor : props.theme.onLightColorMediumEmphasis)};
  }

  svg {
    margin-top: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .input__prefix {
    > svg {
      margin-left: 0.75rem;
      margin-right: -0.5rem;
    }
  }

  .input__suffix {
    > svg {
      margin-left: -0.5rem;
      margin-right: 0.75rem;
    }
  }

  ${applyStyleModifiers(Modifiers)};
`;

export const TextField = ({ prefix, suffix, label, helpertext, isFilled, error, modifiers, formRef, name, type }) => {
  //

  const helper = error || helpertext;

  return (
    <Root isFilled={isFilled} error={error} modifiers={modifiers}>
      {prefix && <div className="input__prefix">{prefix}</div>}
      <input className="roboto-text-base roboto-text-base--subtitle" ref={formRef} name={name} type={type} id={name} />
      <div className="state-overlay" />
      <label className="roboto-text-base roboto-text-base--subtitle input__label" htmlFor={name}>
        {label}
      </label>
      <div className="roboto-text-sm input__helper-text">{helper}</div>
      {suffix && <div className="input__suffix">{suffix}</div>}
    </Root>
  );
};

TextField.propTypes = {
  modifiers: styleModifierPropTypes(Modifiers),
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  label: PropTypes.string,
  helpertext: PropTypes.string,
  error: PropTypes.node,
  isFilled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  formRef: PropTypes.func,
};

TextField.defaultProps = {
  modifiers: () => {},
  prefix: null,
  suffix: null,
  label: 'Label',
  helpertext: '',
  error: null,
  type: 'text',
  formRef: null,
  isFilled: false,
};
