/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const Modifiers = {
  filled: (props) => css`
    .input__label {
      background-color: transparent;
      transform: ${props.isFilled ? `translate3d(-0.5rem, -2rem, 0) scale3d(0.875, 0.875, 1)` : ''};
    }

    .state-overlay {
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${props.theme.lowEmphasisBackgroundColor};
    }

    textarea {
      :hover {
        ~ .state-overlay {
          border-top: 1px solid transparent;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: ${props.theme.hoveredBackgroundColor};
        }
      }

      :focus {
        ~ .state-overlay {
          border-top: 2px solid transparent;
          border-left: 2px solid transparent;
          border-right: 2px solid transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: ${props.theme.focusedBackgroundColor};
        }

        ~ .input__label {
          transform: translate3d(-0.5rem, -2rem, 0) scale3d(0.875, 0.875, 1);
          line-height: 1rem;
        }
      }
    }
  `,
};

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.defaultSurfaceE00};
  height: 6rem;

  textarea {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    height: 4.5rem;
    width: 100%;
    background-color: transparent;
    margin-left: 1rem;
    padding-right: 0.75rem;
    border: none;
    resize: none;

    :hover {
      ~ .state-overlay {
        /* background-color: ${({ theme }) => theme.hoveredBackgroundColor}; */
        border: 1px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColorHover)};
      }
    }

    :focus {
      ~ .input__label {
        transform: translate3d(-0.5rem, -1.5rem, 0) scale3d(0.875, 0.875, 1);
        line-height: 1rem;
      }
      ~ .state-overlay {
        border: 2px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColorHover)};
      }
    }
  }

  .state-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    transition: 0.2s;
    border: 1px solid ${(props) => (props.error ? props.theme.errorColor : props.theme.outlineColor)};
  }

  .input__label {
    position: absolute;
    top: 1rem;
    transition: all 0.2s ease;
    padding: 0 0.25rem;
    background-color: ${({ theme }) => theme.defaultSurfaceE00};
    color: ${(props) => (props.error ? props.theme.errorColor : props.theme.onLightColorHighEmphasis)};
    margin-left: 1rem;
    line-height: 1rem;
    transform: ${(props) => (props.isFilled ? 'translate3d(-0.5rem, -1.5rem, 0) scale3d(0.875, 0.875, 1)' : '')};
  }

  .input__helper-text {
    position: absolute;
    bottom: -1.25rem;
    left: 1rem;
    color: ${(props) => (props.error ? props.theme.errorColor : props.theme.onLightColorMediumEmphasis)};
  }

  ${applyStyleModifiers(Modifiers)};
`;

export const TextArea = ({ label, helpertext, value, error, modifiers, onChange, formRef, name }) => {
  //

  const inputProps = formRef ? null : { value, onChange };
  const isFilled = value.length > 0;
  const helper = error || helpertext;

  return (
    <Root isFilled={isFilled} error={error} modifiers={modifiers}>
      <textarea className="roboto-text-base roboto-text-base--subtitle" name={name} ref={formRef} {...inputProps} />
      <div className="state-overlay" />
      <div className="roboto-text-base roboto-text-base--subtitle input__label">{label}</div>
      <div className="roboto-text-sm input__helper-text">{helper}</div>
    </Root>
  );
};

TextArea.propTypes = {
  modifiers: styleModifierPropTypes(Modifiers),
  label: PropTypes.string,
  helpertext: PropTypes.string,
  error: PropTypes.node,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  formRef: PropTypes.func,
};

TextArea.defaultProps = {
  modifiers: () => {},
  label: 'Label',
  helpertext: '',
  error: null,
  value: '',
  onChange: null,
  formRef: null,
};
