/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { window } from 'browser-monads';
import { AnimatePresence } from 'framer-motion';
import { useScrollPosition } from '../../utils';
import { Masthead } from '../Masthead';
import { MenuIcon } from '../Svg/MenuIcon';
import { IconButton } from '../Elements';
import { openMobileMenu } from '../../redux/mobileMenu';
import { ThemeSelector } from '../ThemeSelector';
import { Search } from '../Search';
import { LogoType } from '../Svg/LogoType';

import {
  defaultStyles,
  pagesWithStyles,
  scrolledFromTopStyles,
  animatedHiddenStyles,
  consideredScrolled,
} from './config';

const menuSwitchWidth = '60rem';

const Root = styled.header`
  width: 100%;
  position: relative;
  z-index: 400;
`;

const Nav = styled.nav`
  position: ${(props) => props.navStyles.position};
  width: 100%;
  top: 0;
  background-color: ${(props) => props.theme.navbarBackgroundColor};
  transition: 0.3s;
  animation: ${(props) => props.navStyles.animation};
  background-color: ${(props) => props.navStyles.backgroundColor};
  z-index: 2;
  box-shadow: ${(props) => props.navStyles.elevation};
  margin-bottom: ${(props) => props.navStyles.bottomMargin};

  .nav__inner-container {
    position: relative;
    box-sizing: border-box;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 81.5rem;
    width: calc(100vw - 1rem);
    transition: height 0.3s;
    @media (min-width: 81.5rem) {
      margin-left: calc(calc(100vw - 82.5rem) / 2);
    }
  }

  svg {
    transition: 0.3s;
    fill: ${(props) => props.navStyles.iconFillColor};
  }

  .logo-container {
    display: none;
    height: 100%;
    svg {
      height: 1.75rem;
      fill: ${({ theme }) => theme.primaryColor};
    }
    @media (min-width: 30rem) {
      svg {
        height: 2rem;
      }
    }
  }

  .nav__left-group {
    display: flex;
    align-items: center;
  }

  .nav__center-group {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .nav__right-group {
    display: flex;
    align-items: center;
  }

  .link__group {
    margin-right: 1rem;
    display: none;
    a {
      position: relative;
      text-decoration: none;
      margin-left: 1.5rem;
      color: ${({ theme }) => theme.navbarLinkColor};
      color: ${(props) => props.navStyles.linkColor};
      white-space: nowrap;
    }
  }

  .nav__theme-selector {
    display: none;
    @media (min-width: 37.5rem) {
      display: block;
    }
  }

  .active {
    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 1rem);
      height: calc(100% + 0.25rem);
      border-radius: ${({ theme }) => theme.borderRadius};
      z-index: -1;
      top: -0.125rem;
      left: -0.5rem;
      background-color: ${({ theme }) => theme.hoveredBackgroundColor};
    }
  }

  @media (min-width: 440px) {
    .logo-container {
      display: block;
    }
  }

  // Menu switching actions below
  @media (min-width: ${menuSwitchWidth}) {
    .mobilemenu-container {
      display: none;
    }

    .link__group {
      display: block;
    }

    .nav__inner-container {
      height: ${(props) => props.navStyles.menuHeight};
    }

    .logo-container {
      svg {
        height: ${(props) => props.navStyles.logoHeight};
      }
    }
  }

  @keyframes nav-show {
    0% {
      transform: translate3d(0, -100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes nav-hide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -100%, 0);
    }
  }
`;

const MastheadContainer = styled.div`
  display: ${(props) => (props.navStyles.isMastheadDisabled ? 'none' : 'block')};
`;

const Spacer = styled.div`
  height: ${(props) => props.navStyles.spacerHeight};
`;

const Component = ({ path, originalPath, showMasthead, dispatchOpenMobileMenu }) => {
  //
  const [navAtTopStyles, setNavAtTopStyles] = useState({});
  const [navStyles, setNavStyles] = useState({});
  const [direction, setDirection] = useState(null);
  const [prevDirection, setPrevDirection] = useState(null);
  const [prevScroll, setPrevScroll] = useState(-window.scrollY);
  const [curScroll, setCurScroll] = useState(0);
  const [hasScrolledUp, setHasScrolledUp] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState('static');

  useEffect(() => {
    const [extractedStyles] = pagesWithStyles.filter((obj) => {
      return obj.path === originalPath;
    });
    if (extractedStyles) {
      setNavbarStyle(extractedStyles.navbarStyle);
      setNavStyles(extractedStyles);
      setNavAtTopStyles(extractedStyles);
    }
    if (!extractedStyles) {
      setNavbarStyle(defaultStyles.navbarStyle);
      setNavStyles(defaultStyles);
      setNavAtTopStyles(defaultStyles);
    }
  }, [originalPath]);

  useScrollPosition(
    ({ currPos }) => {
      //
      setCurScroll(currPos.y);
      if (curScroll > prevScroll) {
        // scrolled up
        setDirection('up');
      }
      if (curScroll < prevScroll) {
        // scrolled down
        setDirection('down');
      }
      setPrevScroll(curScroll);

      if (currPos.y >= 0) {
        setHasScrolledUp(false);
        const appliedStyles = { ...navAtTopStyles };
        if (JSON.stringify(appliedStyles) !== JSON.stringify(navStyles)) {
          setNavStyles(navAtTopStyles);
        }
      }
      if (currPos.y < -consideredScrolled) {
        if (navbarStyle === 'fixed') {
          const appliedStyles = { ...scrolledFromTopStyles };
          if (JSON.stringify(appliedStyles) !== JSON.stringify(navStyles)) {
            setNavStyles(scrolledFromTopStyles);
          }
        }
        if (navbarStyle === 'hide') {
          if (direction !== prevDirection) {
            if (direction === 'up') {
              // Show
              const appliedStyles = { ...scrolledFromTopStyles };
              if (JSON.stringify(appliedStyles) !== JSON.stringify(navStyles)) {
                setNavStyles(scrolledFromTopStyles);
              }
              setHasScrolledUp(true);
              setPrevDirection('up');
            }
            if (direction === 'down') {
              // Hide
              if (hasScrolledUp) {
                const appliedStyles = { ...animatedHiddenStyles };
                if (JSON.stringify(appliedStyles) !== JSON.stringify(navStyles)) {
                  setNavStyles(animatedHiddenStyles);
                }
              }
              setPrevDirection('down');
            }
          }
        }
      }
    },
    [navStyles, prevScroll, curScroll, prevDirection, direction, hasScrolledUp, navbarStyle]
  );

  return (
    <>
      <Root>
        <MastheadContainer navStyles={navStyles}>
          <AnimatePresence>{showMasthead && <Masthead />}</AnimatePresence>
        </MastheadContainer>
        <Nav navStyles={navStyles}>
          <div className="nav__inner-container">
            <div className="nav__left-group">
              <div className="mobilemenu-container">
                <IconButton
                  onClick={() => dispatchOpenMobileMenu()}
                  modifiers={['noShadow']}
                  ariaLabel="Open mobile Menu"
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className="link__group">
                <Link
                  to="/menu/soup"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  Menu
                </Link>
                <Link
                  to="/about-us"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  About Us
                </Link>
                <Link
                  to="/book"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  Book
                </Link>
                <Link
                  to="/reviews"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  Reviews
                </Link>
                <Link
                  to="/contact"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  Contact
                </Link>
              </div>
            </div>
            <div className="nav__center-group">
              <Link to="/" aria-label="Back to homepage">
                <div className="logo-container">
                  <LogoType />
                </div>
              </Link>
            </div>
            <div className="nav__right-group">
              {/* <div className="link__group">
                <Link
                  to="/promotions"
                  className="roboto-text-base roboto-text-base--body"
                  activeClassName="active"
                  partiallyActive
                >
                  Promotions
                </Link>
              </div> */}
              <Search />
              <div className="nav__theme-selector">
                <ThemeSelector />
              </div>
            </div>
          </div>
        </Nav>
      </Root>
      <Spacer navStyles={navStyles} />
    </>
  );
};

const mapDispatchToProps = {
  dispatchOpenMobileMenu: openMobileMenu,
};

const mapStateToProps = (state) => ({
  showMasthead: state.announcement.showMasthead,
});

export const Header = memo(connect(mapStateToProps, mapDispatchToProps)(Component));

Component.propTypes = {
  path: PropTypes.string.isRequired,
  originalPath: PropTypes.string.isRequired,
  showMasthead: PropTypes.bool.isRequired,
  dispatchOpenMobileMenu: PropTypes.func.isRequired,
};
