/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { localStorageWrite } from '../utils';

const themeInitialState = {
  useTheme: 'default',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState: themeInitialState,
  reducers: {
    setTheme: (state, { payload }) => {
      state.useTheme = payload;
      switch (payload) {
        case 'default':
          localStorageWrite('theme', 'default');
          break;
        case 'dark':
          localStorageWrite('theme', 'dark');
          break;
        default:
          document.body.style.backgroundColor = '#ffffff';
      }
    },
  },
});

export const { setTheme } = themeSlice.actions;
