/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTheme } from '../../redux/theme';
import { IconButton } from '../Elements';
import { DarkModeIcon } from '../Svg/DarkModeIcon';
import { LightModeIcon } from '../Svg/LightModeIcon';

const Component = ({ useTheme, dispatchSetTheme }) => {
  //

  const toggleTheme = () => {
    if (useTheme === 'default') {
      dispatchSetTheme('dark');
    }

    if (useTheme === 'dark') {
      dispatchSetTheme('default');
    }
  };

  return (
    <IconButton type="button" onClick={toggleTheme} modifiers={['noShadow']} ariaLabel="Toggle theme">
      {useTheme === 'default' ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
};

const mapStateToProps = (state) => ({
  useTheme: state.theme.useTheme,
});

const mapDispatchToProps = {
  dispatchSetTheme: setTheme,
};

export const ThemeSelector = connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  useTheme: PropTypes.string.isRequired,
  dispatchSetTheme: PropTypes.func.isRequired,
};
