/* eslint-disable import/prefer-default-export */
import { elevations } from './elevations';

const DefaultTheme = {
  ...elevations,

  bodyBackgroundColor: '#ffffff',
  borderRadius: '0.25rem',
  heightDefault: '3rem',

  primaryColor: '#f05f2a',
  primaryVariantColor: '#c04c22',
  secondaryColor: '#3f1c0f',
  secondaryVariantColor: '#210000',

  errorColor: '#B00020',
  selectedColor: '',
  onLightSecondaryColor: '#3f1c0f',
  onLightLinkActivatedColor: '#f05f2a',
  onDarkLinkColor: '#fbcfbf',
  onDarkLinkActivatedColor: '#f9bfaa',
  onLightColorHighEmphasis: 'rgba(0,0,0,0.87)',
  onLightColorMediumEmphasis: 'rgba(0,0,0,0.6)',

  onLightColorDisabled: 'rgba(0,0,0,0.38)',
  onDarkColorHighEmphasis: 'rgba(255,255,255,0.87)',
  onDarkColorMediumEmphasis: 'rgba(255,255,255,0.6)',
  onDarkColorDisabled: 'rgba(255,255,255,0.38)',
  onLightDivider: 'rgba(0,0,0,0.12)',
  onDarkDivider: 'rgba(255,255,255,0.3)',
  containedButtonBackgroundColor: '#c04c22',
  containedButtonTextColor: '#ffffff',
  outlinedButtonTextColor: '#000000',
  hoveredTouchTarget: 'rgba(0,0,0,0.05)',
  lowEmphasisSolidBackgroundColor: 'rgba(0,0,0)',
  lowEmphasisBackgroundColor: 'rgba(0,0,0,0.03)',
  hoveredBackgroundColor: 'rgba(0,0,0,0.07)',
  focusedBackgroundColor: 'rgba(0,0,0,0.05)',
  outlineColor: 'rgba(0,0,0,0.37)',
  outlineColorHover: 'rgba(0,0,0,0.87)',
  outlineColorFocused: 'rgba(0,0,0,0.87)',
  outlinePrimaryColor: '#f69f7f',

  defaultSurfaceE00: 'rgba(255, 255, 255, 1)',
  defaultTransparentSurfaceE00: 'rgba(255, 255, 255, 0)',
  defaultSurfaceE01: '#FFFFFF',
  defaultSurfaceE02: '#FFFFFF',
  defaultSurfaceE03: '#FFFFFF',
  defaultSurfaceE04: '#FFFFFF',
  defaultSurfaceE05: '#FFFFFF',
  defaultSurfaceE06: '#FFFFFF',
  defaultSurfaceE07: '#FFFFFF',
  defaultSurfaceE08: '#FFFFFF',
  defaultSurfaceE09: '#FFFFFF',
  defaultSurfaceE10: '#FFFFFF',
  defaultSurfaceE11: '#FFFFFF',
  defaultSurfaceE12: '#FFFFFF',
  defaultSurfaceE13: '#FFFFFF',
  defaultSurfaceE14: '#FFFFFF',
  defaultSurfaceE15: '#FFFFFF',
  defaultSurfaceE16: '#FFFFFF',
  defaultSurfaceE17: '#FFFFFF',
  defaultSurfaceE18: '#FFFFFF',
  defaultSurfaceE19: '#FFFFFF',
  defaultSurfaceE20: '#FFFFFF',
  defaultSurfaceE21: '#FFFFFF',
  defaultSurfaceE22: '#FFFFFF',
  defaultSurfaceE23: '#FFFFFF',
  defaultSurfaceE24: '#FFFFFF',

  navbarBackgroundColor: '#ffffff',
  navbarLinkColor: '#3f1c0f',
};

const DarkTheme = {
  ...elevations,

  bodyBackgroundColor: '#121212',
  borderRadius: '0.25rem',
  heightDefault: '3rem',

  primaryColor: '#ff9057',
  primaryVariantColor: '#ff9057',
  secondaryColor: '#6c4435',
  secondaryVariantColor: '#3f1c0f',

  errorColor: '#cf6679',
  selectedColor: '',
  onLightSecondaryColor: '#d9d2cf',
  onLightLinkActivatedColor: '#ff9057',
  onDarkLinkColor: '#fbcfbf',
  onDarkLinkActivatedColor: '#fcdfd4',
  onLightColorHighEmphasis: 'rgba(255,255,255,0.87)',
  onLightColorMediumEmphasis: 'rgba(255,255,255,0.6)',
  onLightColorDisabled: 'rgba(255,255,255,0.38)',
  onDarkColorHighEmphasis: 'rgba(255,255,255,0.87)',
  onDarkColorMediumEmphasis: 'rgba(255,255,255,0.6)',
  onDarkColorDisabled: 'rgba(255,255,255,0.38)',
  onLightDivider: 'rgba(255,255,255,0.3)',
  onDarkDivider: 'rgba(255,255,255,0.3)',
  containedButtonBackgroundColor: '#ff9057',
  containedButtonTextColor: '#000000',
  outlinedButtonTextColor: '#ffffff',
  hoveredTouchTarget: 'rgba(255,255,255,0.2)',
  lowEmphasisSolidBackgroundColor: 'rgba(255,255,255)',
  lowEmphasisBackgroundColor: 'rgba(255,255,255,0.15)',
  hoveredBackgroundColor: 'rgba(255,255,255,0.2)',
  focusedBackgroundColor: 'rgba(255,255,255,0.10)',
  outlineColor: 'rgba(255,255,255,0.37)',
  outlineColorHover: 'rgba(255,255,255,0.87)',
  outlineColorFocused: 'rgba(255,255,255,0.87)',
  outlinePrimaryColor: '#f9bfaa',

  defaultSurfaceE00: 'rgba(18,18,18,1)',
  defaultTransparentSurfaceE00: 'rgba(18, 18, 18, 0)',
  defaultSurfaceE01: '#1D1D1D',
  defaultSurfaceE02: '#222222',
  defaultSurfaceE03: '#252525',
  defaultSurfaceE04: '#262626',
  defaultSurfaceE05: '#292929',
  defaultSurfaceE06: '#2C2C2C',
  defaultSurfaceE07: '#2D2D2D',
  defaultSurfaceE08: '#2E2E2E',
  defaultSurfaceE09: '#2F2F2F',
  defaultSurfaceE10: '#313131',
  defaultSurfaceE11: '#323232',
  defaultSurfaceE12: '#333333',
  defaultSurfaceE13: '#333333',
  defaultSurfaceE14: '#333333',
  defaultSurfaceE15: '#343434',
  defaultSurfaceE16: '#353535',
  defaultSurfaceE17: '#353535',
  defaultSurfaceE18: '#353535',
  defaultSurfaceE19: '#353535',
  defaultSurfaceE20: '#363636',
  defaultSurfaceE21: '#363636',
  defaultSurfaceE22: '#373737',
  defaultSurfaceE23: '#373737',
  defaultSurfaceE24: '#373737',

  navbarBackgroundColor: '#2C2C2C',
  navbarLinkColor: 'rgba(255,255,255,0.87)',
};

export const themes = {
  default: DefaultTheme,
  dark: DarkTheme,
};
