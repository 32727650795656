import React from 'react';
import styled from 'styled-components';
import { LogoType } from '../../components/Svg/LogoType';
import LogoImage from './ImageLogoMark';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    position: absolute;
    top: 2rem;
    color: #f05f2a;
    font-size: 1.5rem;
  }

  h2 {
    font-weight: 300;
    letter-spacing: 6px;
    margin-bottom: 6rem;
    font-size: 1.5rem;
  }

  h3 {
    font-weight: 600;
    text-align: center;
    color: var(--grey-8);
  }
`;

const LogoImageWrapper = styled.div`
  width: 100%;
  max-height: 200px;
  max-width: 200px;
  margin-bottom: 2rem;
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 0 2rem;
  svg {
    fill: #f05f2a;
  }
`;

const Maintanence = () => {
  //
  return (
    <Wrapper>
      <LogoImageWrapper>
        <LogoImage />
      </LogoImageWrapper>
      <LogoWrapper>
        <LogoType />
      </LogoWrapper>
      <p>Website coming soon. Stay tuned.</p>
      <h2 className="top roboto-text-sm roboto-text-sm--body hero-subtitle">INDIAN DELICACY</h2>
      <h3 className="top roboto-text-base roboto-text--body hero-heading">
        70 Boat Quay
        <br />
        Singapore
      </h3>
    </Wrapper>
  );
};

export default Maintanence;
