/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import { Footer } from '../Footer';
import { MobileMenu } from '../MobileMenu';
import { Drawer } from '../Drawer';
import { themes } from '../../styles/themes/themes';
import { getAnnouncement } from '../../redux/announcement';
import { setTheme } from '../../redux/theme';
import { localStorageRead } from '../../utils';
import { Header } from '../Header';
import { GlobalStyles } from '../../styles/globalStyles';
import { Banner } from '../Banner';
import { closeSearchBox } from '../../redux/search';
import Maintanence from '../../page-sections/maintanence/Maintanence';

const Component = ({
  children,
  uri,
  path,
  originalPath,
  useTheme,
  showMobileMenu,
  showDrawer,
  showBanner,
  dispatchSetTheme,
  dispatchGetAnnouncement,
  dispatchCloseSearchBox,
  location,
}) => {
  //

  useEffect(() => {
    dispatchSetTheme(localStorageRead('theme', 'default'));
    dispatchGetAnnouncement();
  }, []);

  useEffect(() => {
    dispatchCloseSearchBox();
  }, [uri, location.state]);

  return (
    <ThemeProvider theme={themes[useTheme]}>
      <GlobalStyles />
      <AnimatePresence>{showBanner && <Banner />}</AnimatePresence>
      <AnimatePresence>{showMobileMenu && <MobileMenu />}</AnimatePresence>
      <AnimatePresence>{showDrawer && <Drawer />}</AnimatePresence>
      <Header uri={uri} path={path} originalPath={originalPath} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
    // <Maintanence />
  );
};

const mapStateToProps = (state) => ({
  showMobileMenu: state.mobileMenu.showMobileMenu,
  showDrawer: state.drawer.showDrawer,
  showBanner: state.announcement.showBanner,
  useTheme: state.theme.useTheme,
});

const mapDispatchToProps = {
  dispatchSetTheme: setTheme,
  dispatchGetAnnouncement: getAnnouncement,
  dispatchCloseSearchBox: closeSearchBox,
};

export const Layout = connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  uri: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  originalPath: PropTypes.string.isRequired,
  useTheme: PropTypes.string.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  showBanner: PropTypes.bool.isRequired,
  dispatchSetTheme: PropTypes.func.isRequired,
  dispatchGetAnnouncement: PropTypes.func.isRequired,
  dispatchCloseSearchBox: PropTypes.func.isRequired,
};
