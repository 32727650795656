/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getYear } from 'date-fns';
import { useSiteMetadata } from '../../utils';
import { PlaceIcon } from '../Svg/PlaceIcon';
import { FacebookIcon } from '../Svg/FacebookIcon';
import { PhoneIcon } from '../Svg/PhoneIcon';
import { EmailIcon } from '../Svg/EmailIcon';
import { MapIcon } from '../Svg/MapIcon';
import { WhatsappIcon } from '../Svg/WhatsappIcon';
import { Container } from '../Elements';

const Root = styled.footer`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.onDarkColorHighEmphasis};
  padding: 4rem 0;

  .nowrap {
    white-space: nowrap;
  }

  a {
    transition: 0.3s;
    line-height: 2;
    color: ${({ theme }) => theme.onDarkLinkColor};
    &:hover {
      color: ${({ theme }) => theme.onDarkLinkActivatedColor};
    }
  }

  .footer__grid {
    display: grid;
    margin: 0 auto;
    .footer__1 {
      grid-area: footer1;
    }
    .footer__2 {
      grid-area: footer2;
    }
    .footer__3 {
      grid-area: footer3;
    }
    .footer__4 {
      grid-area: footer4;
    }
    .footer__5 {
      grid-area: footer5;
      opacity: 0.7;
    }

    .footer__1,
    .footer__2,
    .footer__3,
    .footer__4,
    .footer__5 {
      text-align: center;
    }

    grid-template-areas:
      'footer1'
      'footer2'
      'footer3'
      'footer4'
      'footer5'
      'footer6';

    grid-row-gap: 3rem;

    .footer__heading {
      margin-bottom: 0.5rem;
      opacity: 0.7;
    }
    .footer__subtitle {
      margin-bottom: 0.25rem;
    }
    .footer__hour-block {
      margin-bottom: 1rem;
    }

    .icon-title-block {
      margin-bottom: 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      padding-right: 2.5rem;
    }

    .address-title {
      margin-bottom: 0;
    }

    .facebook {
      margin-bottom: 2rem;
    }

    .icon-container {
      svg {
        fill: #ffffff;
        margin-top: 0.125rem;
      }
      width: 2.5rem;
    }

    @media (min-width: 860px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        'footer1 footer3 footer2'
        'footer1 footer3 footer4'
        'footer5 footer5 footer5';

      grid-column-gap: 1.5rem;

      .footer__1 {
        text-align: left;
      }
      .footer__2,
      .footer__4 {
        text-align: right;
      }

      .address,
      .map,
      .facebook {
        justify-content: flex-start;
      }

      .phone,
      .email {
        justify-content: flex-end;
        padding-right: 0;
      }
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        'footer1 footer2 footer3 footer4'
        'footer5 footer5 footer5 footer5';

      grid-column-gap: 1.5rem;

      .footer__2 {
        text-align: center;
      }

      .phone,
      .email {
        justify-content: center;
      }
    }
  }
`;

export const Footer = () => {
  //
  const data = useSiteMetadata();

  const {
    siteName,
    contact: {
      address: { streetAddress, addressLocality, postalCode },
      telephone,
      email,
      googleMap,
      whatsapp,
    },
    business: {
      customHours: {
        mon: { block1: monDinner },
        tueToSun: { block1: tueToSunLunch, block2: tueToSunDinner },
      },
    },
    socialMedia: { facebook },
  } = data;

  return (
    <Root>
      <Container>
        <div className="footer__grid">
          <div className="footer__1">
            <div className="icon-title-block facebook">
              <div className="icon-container">
                <FacebookIcon />
              </div>
              <div className="title-container">
                <a
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="facebook"
                  className="roboto-text-base roboto-text-base--subtitle"
                >
                  Follow Us
                </a>
              </div>
            </div>

            <div className="icon-title-block address address-title">
              <div className="icon-container" />
              <div className="title-container">
                <div className="roboto-text-lg footer__heading">Address</div>
              </div>
            </div>
            <div className="icon-title-block address">
              <div className="icon-container">
                <MapIcon />
              </div>
              <div className="title-container">
                <div className="roboto-text-base roboto-text-base--subtitle">
                  {`${streetAddress},`}
                  <br />
                  {`${addressLocality} ${postalCode}`}
                </div>
              </div>
            </div>
            <div className="icon-title-block map">
              <div className="icon-container">
                <PlaceIcon />
              </div>
              <div className="title-container">
                <a href={googleMap} target="_blank" rel="noopener noreferrer">
                  <div className="roboto-text-base roboto-text-base--subtitle">Find us on Google Map</div>
                </a>
              </div>
            </div>
          </div>

          <div className="footer__2">
            <div className="roboto-text-lg footer__heading">Contact Us</div>
            {/* <div className="icon-title-block phone">
              <div className="icon-container">
                <WhatsappIcon />
              </div>
              <div className="title-container">
                <a
                  className="roboto-text-base roboto-text-base--subtitle"
                  href={`https://wa.me/${whatsapp.replace(/\s+|-/g, '')}`}
                >
                  <span className="nowrap">{whatsapp}</span>
                </a>
              </div>
            </div> */}
            <div className="icon-title-block phone">
              <div className="icon-container">
                <PhoneIcon />
              </div>
              <div className="title-container">
                <a className="roboto-text-base roboto-text-base--subtitle" href={`tel:${telephone}`}>
                  <span className="nowrap">{telephone}</span>
                </a>
              </div>
            </div>
            <div className="icon-title-block email">
              <div className="icon-container">
                <EmailIcon />
              </div>
              <div className="title-container">
                <a className="roboto-text-base roboto-text-base--subtitle" href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
            </div>
          </div>
          <div className="footer__3">
            <div className="roboto-text-lg footer__heading">Opening Hours</div>
            {/* <div className="footer__hour-block">
              <div className="roboto-text-sm roboto-text-sm--subtitle footer__subtitle">Monday</div>
              <div className="roboto-text-base roboto-text-base--subtitle">{`Dinner ${monDinner}`}</div>
              <div className="roboto-text-base roboto-text-base--subtitle">{monDinner}</div>
            </div> */}
            <div className="footer__hour-block">
              <div className="roboto-text-sm roboto-text-sm--subtitle footer__subtitle">Tuesday - Sunday</div>
              <div className="roboto-text-base roboto-text-base--subtitle">{`Lunch ${tueToSunLunch}`}</div>
              <div className="roboto-text-base roboto-text-base--subtitle">{`Dinner ${tueToSunDinner}`}</div>
            </div>
          </div>
          <div className="footer__4">
            <div className="roboto-text-lg footer__heading">Legal</div>
            <div>
              <Link to="/privacy-policy" className="roboto-text-base roboto-text-base--subtitle">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link to="/terms-of-use" className="roboto-text-base roboto-text-base--subtitle">
                Terms of Use
              </Link>
            </div>
          </div>
          <div className="footer__5">
            <div className="roboto-text-sm roboto-text-sm--subtitle">
              &copy;{` ${getYear(new Date())} Express Tandoor Pte Ltd. All rights reserved.`}
            </div>
          </div>
        </div>
      </Container>
    </Root>
  );
};
