/* eslint-disable import/prefer-default-export */
import { createGlobalStyle, css } from 'styled-components';
import { materialUi } from './materialUi';
import { reactSlick } from './reactSlick';

export const GlobalStyles = createGlobalStyle`${css`
  body {
    background-color: ${({ theme }) => theme.bodyBackgroundColor};
    transition: background-color 0.7s;
  }

  ${materialUi};
  ${reactSlick};

  .visually-hidden {
    width: 1px;
    height: 1px;
    position: absolute !important;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
  }

  .link {
    &:link,
    &:visited {
      background-color: transparent;
      /* font-weight: 500; */
      text-decoration: underline;
      transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);
      font-family: inherit;
      font-size: inherit;
      color: ${({ theme }) => theme.onLightColorHighEmphasis};
    }

    &:active,
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.onLightLinkActivatedColor};
    }
  }

  .photoframe {
    border: 0.5rem solid ${({ theme }) => theme.defaultSurfaceE04};
    outline: 1px solid ${({ theme }) => theme.outlinePrimaryColor};
  }

  .section-heading {
    color: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.125rem;

    &--on-dark {
      color: #ffffff;
    }
  }
`}`;
