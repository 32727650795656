/* eslint-disable import/prefer-default-export */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { closeMasthead } from '../../redux/announcement';
import { IconButton } from '../Elements';
import { CloseIcon } from '../Svg/CloseIcon';

const MastHeadAnimation = {
  hidden: {
    height: 0,
  },
  open: {
    height: '2rem',
    transition: {
      ease: 'linear',
      duration: 0.15,
    },
  },
  exit: {
    height: 0,
    transition: {
      ease: 'linear',
      duration: 0.15,
    },
  },
};

const Root = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
  display: none;
  z-index: 1;
  background-color: ${({ theme }) => theme.secondaryColor};
  @media (min-width: 40rem) {
    display: flex;
  }

  svg {
    fill: ${({ theme }) => theme.onDarkColorHighEmphasis};
  }
`;

const MessageWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  left: 1rem;
  right: 3rem;
  top: 0;
  bottom: 0;
  height: 2rem;
  z-index: 1;
  color: ${({ theme }) => theme.onDarkColorHighEmphasis};

  .message {
    position: absolute;
    left: 0;
    transform: translate(0);
    width: 100%;
    top: 0;
    height: 100%;
    line-height: 1;
    margin: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .animation-1 {
    animation: announcementAnimation1 linear 20s infinite;
  }

  .animation-2 {
    animation: announcementAnimation2 linear 20s infinite;
  }

  @keyframes announcementAnimation1 {
    from {
      transform: translate3d(0%, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes announcementAnimation2 {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0%, 0, 0);
    }
  }
`;

const Component = ({ mastheadMessage, isDismissable, dispatchCloseMasthead }) => {
  //

  return (
    <Root initial="hidden" animate="open" exit="exit" variants={MastHeadAnimation}>
      <MessageWrapper>
        <p className="message animation-1 roboto-text-xs roboto-text-xs--caption">{mastheadMessage}</p>
        <p className="message animation-2 roboto-text-xs roboto-text-xs--caption">{mastheadMessage}</p>
      </MessageWrapper>
      {isDismissable && (
        <IconButton onClick={() => dispatchCloseMasthead()} modifiers={['small']} ariaLabel="Close Announcement">
          <CloseIcon />
        </IconButton>
      )}
    </Root>
  );
};

const mapStateToProps = (state) => ({
  isDismissable: state.announcement.isDismissable,
  mastheadMessage: state.announcement.message,
});

const mapDispatchToProps = {
  dispatchCloseMasthead: closeMasthead,
};

export const Masthead = connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  isDismissable: PropTypes.bool.isRequired,
  dispatchCloseMasthead: PropTypes.func.isRequired,
  mastheadMessage: PropTypes.string.isRequired,
};
