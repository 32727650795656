/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
`;

export const FormField = ({ children }) => {
  //
  return <Root>{children}</Root>;
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
};
