/* eslint-disable import/prefer-default-export */

import { css } from 'styled-components';

export const materialUi = css`
  .MuiPaper-root {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    svg {
      fill: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
  }

  .MuiInputBase-root {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .MuiPaper-elevation8 {
    background-color: ${({ theme }) => theme.defaultSurfaceE08};
  }

  .MuiPickersDay-day {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    &:hover {
      background-color: ${({ theme }) => theme.hoveredTouchTarget};
    }
  }

  .MuiPickersDay-dayDisabled {
    color: ${({ theme }) => theme.onLightColorDisabled};
  }

  .MuiPickersDay-current {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    background-color: ${({ theme }) => theme.hoveredTouchTarget};
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
    color: ${({ theme }) => theme.containedButtonTextColor};
    &:focus {
      background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
    }
    &:hover {
      background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
    }

    &.MuiPickersDay-current {
      color: ${({ theme }) => theme.onDarkColorHighEmphasis};
    }
  }

  .MuiPickersBasePicker-container {
    padding-bottom: 1rem;
  }

  .MuiPickersCalendarHeader-iconButton {
    background-color: transparent;
    &:hover {
      background-color: ${({ theme }) => theme.hoveredTouchTarget};
    }
  }

  .MuiListItem-root {
    &:hover {
      background-color: ${({ theme }) => theme.hoveredBackgroundColor};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
      color: ${({ theme }) => theme.containedButtonTextColor};
      &:hover {
        background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
        color: ${({ theme }) => theme.containedButtonTextColor};
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.outlineColorFocused};
  }

  .MuiOutlinedInput-root {
    height: ${({ theme }) => theme.heightDefault};
    border-radius: ${({ theme }) => theme.borderRadius};
    overflow: hidden;
    transition: background-color 0.3s;
    svg {
      fill: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
    &:hover {
      background-color: ${({ theme }) => theme.hoveredBackgroundColor};
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.outlineColorHover};
      }
    }
    fieldset {
      border-color: ${({ theme }) => theme.outlineColor};
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    background-color: ${({ theme }) => theme.hoveredBackgroundColor};
  }

  .MuiOutlinedInput-root.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.errorColor};
    }
  }

  .MuiRadio-root,
  .MuiCheckbox-root {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    svg {
      fill: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
    transition: background-color 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.hoveredTouchTarget};
    }
  }

  .MuiRadio-colorPrimary,
  .MuiRadio-colorSecondary,
  .MuiCheckbox-colorPrimary,
  .MuiCheckbox-colorSecondary,
  .MuiSwitch-colorPrimary,
  .MuiSwitch-colorSecondary {
    &.Mui-checked {
      color: ${({ theme }) => theme.onLightColorHighEmphasis};
      &:hover {
        background-color: ${({ theme }) => theme.hoveredTouchTarget};
      }
      + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.onLightColorHighEmphasis};
      }
    }
    + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
  }

  .Mui-focusVisible {
    background-color: ${({ theme }) => theme.hoveredTouchTarget};
  }

  .MuiFormHelperText-root.Mui-error,
  .Mui-error {
    color: ${({ theme }) => theme.errorColor};
  }
`;
