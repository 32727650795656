/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { closeBanner } from '../../redux/announcement';
import { Button, IconButton } from '../Elements';
import { CloseIcon } from '../Svg/CloseIcon';

const BannerAnimation = {
  hidden: {
    opacity: 1,
    translateY: '-100%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  enter: {
    opacity: 1,
    translateY: '0%',
  },
};

const Root = styled(motion.div)`
  background-color: ${({ theme }) => theme.secondaryColor};
  box-shadow: ${({ theme }) => theme.elevation04};

  position: fixed;
  width: 100%;
  z-index: 500;
  @media (min-width: 40rem) {
    display: none;
  }

  svg {
    fill: ${({ theme }) => theme.onDarkColorHighEmphasis};
  }

  .banner__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 1rem;
    height: 3.5rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .banner__message-container {
    padding: 0 1rem 2rem 1rem;
    text-align: center;
  }

  .banner__title {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.onDarkColorMediumEmphasis};
  }

  .banner__message {
    color: ${({ theme }) => theme.onDarkColorHighEmphasis};
  }

  .banner__footer {
    padding: 1rem 0 2rem 0;
    display: flex;
    justify-content: center;
  }
`;

const Component = ({ bannerHeading, bannerMessage, isDismissable, dispatchCloseBanner }) => {
  //
  return (
    <Root initial="hidden" animate="enter" exit="hidden" variants={BannerAnimation}>
      <div className="banner__header">
        <IconButton onClick={() => dispatchCloseBanner()} modifiers={['noShadow']} ariaLabel="Close banner">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="banner__message-container">
        <div className="roboto-text-lg banner__title">{bannerHeading}</div>
        <p className="roboto-text-base roboto-text-base--body banner__message">{bannerMessage}</p>
      </div>
      <div className="banner__footer">
        <Button onClick={() => dispatchCloseBanner()} modifiers={['on-dark', 'small']}>
          Close
        </Button>
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  isDismissable: state.announcement.isDismissable,
  bannerMessage: state.announcement.message,
  bannerHeading: state.announcement.heading,
});

const mapDispatchToProps = {
  dispatchCloseBanner: closeBanner,
};

export const Banner = connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  isDismissable: PropTypes.bool.isRequired,
  dispatchCloseBanner: PropTypes.func.isRequired,
  bannerMessage: PropTypes.string.isRequired,
  bannerHeading: PropTypes.string.isRequired,
};
