/* eslint-disable import/prefer-default-export */

import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { siteMetadataJson } = useStaticQuery(
    graphql`
      query {
        siteMetadataJson {
          ...SiteMetadata
        }
      }
    `
  );

  return siteMetadataJson;
};
