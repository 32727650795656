/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const Modifiers = {
  contained: ({ theme }) => css`
    background-color: ${theme.containedButtonBackgroundColor};
    color: ${theme.containedButtonTextColor};
    box-shadow: ${theme.elevation02};
    &:hover {
      .state-overlay {
        opacity: 0.08;
      }
      box-shadow: ${theme.elevation08};
    }

    &-activated,
    &:active {
      .state-overlay {
        opacity: 0.32;
      }
      box-shadow: ${theme.elevation08};
    }

    &:disabled {
      &:hover {
        > .state-overlay {
          opacity: 0;
        }
        box-shadow: none;
      }
      opacity: 0.38;
      box-shadow: none;
      cursor: not-allowed;
    }

    &:focus {
      .state-overlay {
        opacity: 0.24;
      }
      box-shadow: ${theme.elevation08};
    }
  `,

  outlined: ({ theme }) => css`
    background-color: transparent;
    border: 1px solid currentColor;
    box-shadow: none;
    color: ${theme.outlinedButtonTextColor};

    &:hover {
      .state-overlay {
        opacity: 0.08;
      }
    }

    &-activated,
    &:active {
      .state-overlay {
        opacity: 0.32;
      }
    }

    &:disabled {
      &:hover {
        .state-overlay {
          opacity: 0;
        }
      }
      opacity: 0.38;
      cursor: not-allowed;
    }

    &:focus {
      .state-overlay {
        opacity: 0.24;
      }
    }
  `,

  text: ({ theme }) => css`
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: ${theme.outlinedButtonTextColor};

    &:hover {
      .state-overlay {
        opacity: 0.08;
      }
    }

    &-activated,
    &:active {
      .state-overlay {
        opacity: 0.32;
      }
    }

    &:disabled {
      &:hover {
        .state-overlay {
          opacity: 0;
        }
      }
      opacity: 0.38;
      cursor: not-allowed;
    }

    &:focus {
      .state-overlay {
        opacity: 0.24;
      }
    }
  `,

  'on-dark': () => css`
    background-color: #ffffff;
    color: #000000;
  `,

  'with-left-icon': () => css`
    padding-left: 0.75rem;
    svg {
      margin-right: 0.5rem;
      fill: currentColor;
    }
  `,

  'with-right-icon': () => css`
    padding-right: 0.75rem;
    svg {
      margin-left: 0.5rem;
      fill: currentColor;
    }
  `,

  large: () => css`
    height: 3.75rem;
  `,

  small: () => css`
    height: 2.25rem;
  `,

  full: () => css`
    width: 100%;
  `,
};

const Root = styled.button`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.heightDefault};
  white-space: nowrap;
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: 0.26s;
  border: none;

  .state-overlay {
    background-color: currentColor;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.26s;
    pointer-events: none;
    border-radius: inherit;
  }

  ${applyStyleModifiers(Modifiers)};
`;

export const Button = ({ modifiers, type, onClick, children }) => {
  //
  return (
    <Root modifiers={modifiers} type={type} className="roboto-text-sm roboto-text-sm--button" onClick={onClick}>
      {children}
      <div className="state-overlay" />
    </Root>
  );
};

Button.propTypes = {
  modifiers: styleModifierPropTypes(Modifiers),
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  modifiers: () => {},
  type: 'button',
  onClick: () => {},
};
