/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const Modifiers = {
  onLight: ({ theme }) => css`
    hr {
      background-color: ${theme.onLightDivider};
    }
  `,
  onDark: ({ theme }) => css`
    hr {
      background-color: ${theme.onDarkDivider};
    }
  `,
};

const Root = styled.div`
  hr {
    border: 0;
    height: 1px;
  }
  ${applyStyleModifiers(Modifiers)};
`;

export const Divider = ({ modifiers, children }) => {
  //
  return (
    <Root modifiers={modifiers}>
      <hr />
      {children}
    </Root>
  );
};

Divider.propTypes = {
  modifiers: styleModifierPropTypes(Modifiers),
  children: PropTypes.node,
};

Divider.defaultProps = {
  modifiers: () => {},
  children: null,
};
