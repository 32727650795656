/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core';
import { closeDrawer } from '../../redux/drawer';

const drawerAnimation = {
  hidden: {
    opacity: 1,
    translateX: '100%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  enter: {
    opacity: 1,
    translateX: '0%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
};

const Root = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Scrim = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DrawerContainer = styled(motion.div)`
  background: #ffffff;
  overflow: hidden;
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  right: 0;
  @media (min-width: 30rem) {
    width: 25rem;
  }
`;

const DrawerContent = styled.div``;

const Component = ({ dispatchCloseDrawer }) => {
  //
  return (
    <Modal open aria-labelledby="modal for mobile navigation" aria-describedby="modal for mobile navigation">
      <Root>
        <Scrim onClick={() => dispatchCloseDrawer()} />
        <DrawerContainer initial="hidden" animate="enter" exit="hidden" variants={drawerAnimation}>
          <DrawerContent>
            <button type="button" onClick={() => dispatchCloseDrawer()}>
              Close
            </button>
            Drawer Content
          </DrawerContent>
        </DrawerContainer>
      </Root>
    </Modal>
  );
};

const mapDispatchToProps = {
  dispatchCloseDrawer: closeDrawer,
};

export const Drawer = connect(null, mapDispatchToProps)(Component);

Component.propTypes = {
  dispatchCloseDrawer: PropTypes.func.isRequired,
};
