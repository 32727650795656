/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const mobileMenuInitialState = {
  showMobileMenu: false,
};

export const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState: mobileMenuInitialState,
  reducers: {
    open: (state) => {
      state.showMobileMenu = true;
    },
    close: (state) => {
      state.showMobileMenu = false;
    },
  },
});

export const { open: openMobileMenu } = mobileMenuSlice.actions;
export const { close: closeMobileMenu } = mobileMenuSlice.actions;
