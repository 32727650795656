/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core';
import { IconButton } from '../Elements';
import { closeMobileMenu } from '../../redux/mobileMenu';
import { CloseIcon } from '../Svg/CloseIcon';
import { ThemeSelector } from '../ThemeSelector';

const menuAnimation = {
  hidden: {
    opacity: 1,
    translateX: '-100%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  enter: {
    opacity: 1,
    translateX: '0%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
};

const Root = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Scrim = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const MenuContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.defaultSurfaceE16};
  box-shadow: ${({ theme }) => theme.elevation16};
  overflow: hidden;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: calc(100% - 3.5rem);
  max-width: 25rem;
  height: 100vh;
  left: 0;

  .menu__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 1rem;
    height: 3.5rem;
    margin-bottom: 1.125rem;
    position: relative;
  }

  .menu__content {
  }

  .utility-container {
    height: 3rem;
    margin-top: 2rem;
    border-top: 1px solid ${({ theme }) => theme.onLightDivider};
    border-bottom: 1px solid ${({ theme }) => theme.onLightDivider};
    padding-left: 0.5rem;
  }

  .utility-container__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .menu__item {
    height: 3rem;
    padding: 0.25rem 0.5rem;
    padding-left: 1rem;
  }
`;

const StyledLink = styled(Link)`
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  position: relative;
  transition: 0.26s;
  align-items: center;
  letter-spacing: 0.078125rem;
  color: ${({ theme }) => theme.onLightSecondaryColor};
  text-decoration: none;
  padding-left: 0.5rem;

  .icon-container {
    width: 4rem;
  }

  > .state-overlay {
    background-color: currentColor;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.26s;
    pointer-events: none;
    border-radius: inherit;
  }

  &:hover {
    > .state-overlay {
      opacity: 0.08;
    }
  }
`;

const LinkItem = ({ to, title, closeFunc }) => {
  return (
    <StyledLink to={to} onClick={() => closeFunc()}>
      {/* <div className="icon-container">Icon</div> */}
      <div className="roboto-text-sm roboto-text-sm--button">{title}</div>
      <div className="state-overlay" />
    </StyledLink>
  );
};

const Component = ({ dispatchCloseMobileMenu }) => {
  //
  return (
    <Modal open aria-labelledby="modal for mobile navigation" aria-describedby="modal for mobile navigation">
      <Root>
        <Scrim onClick={() => dispatchCloseMobileMenu()} />
        <MenuContainer initial="hidden" animate="enter" exit="hidden" variants={menuAnimation}>
          <div className="menu__header">
            <IconButton
              onClick={() => dispatchCloseMobileMenu()}
              modifiers={['noShadow']}
              ariaLabel="Close mobile menu"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="menu__content">
            <div className="menu__item">
              <LinkItem to="/" title="Home" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/promotions" title="Latest Promotions" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/menu/soup" title="Our Menu" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/about-us" title="About Us" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/book" title="Book a Table" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/reviews" title="Reviews" closeFunc={dispatchCloseMobileMenu} />
            </div>
            <div className="menu__item">
              <LinkItem to="/contact" title="Contact Us" closeFunc={dispatchCloseMobileMenu} />
            </div>
          </div>
          <div className="utility-container">
            <div className="utility-container__item">
              <div className="roboto-text-sm roboto-text-sm--button">Switch Mode</div>
              <ThemeSelector />
            </div>
          </div>
        </MenuContainer>
      </Root>
    </Modal>
  );
};

const mapDispatchToProps = {
  dispatchCloseMobileMenu: closeMobileMenu,
};

export const MobileMenu = connect(null, mapDispatchToProps)(Component);

Component.propTypes = {
  dispatchCloseMobileMenu: PropTypes.func.isRequired,
};

LinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  closeFunc: PropTypes.func.isRequired,
};
