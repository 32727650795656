/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { window } from 'browser-monads';
import { SearchBox } from './SearchBox';
import { SearchIcon } from '../Svg/SearchIcon';
import { Button, IconButton } from '../Elements';
import { openSearchBox, closeSearchBox } from '../../redux/search';

const Root = styled.div`
  .large-search {
    /* display: none; */
    margin-right: 0.75rem;

    svg {
      fill: ${({ theme }) => theme.containedButtonTextColor};
    }
  }
  /* @media (min-width: 57.5rem) {
    .small-search {
      display: none;
    }
    .large-search {
      display: block;
    }
  } */
`;

const SearchBoxContainer = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const Scrim = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Component = ({ showSearchBox, dispatchOpenSearchBox, dispatchCloseSearchBox }) => {
  //

  const onSlashKey = (e) => {
    if (e.code === 'Slash' || e.key === '/') {
      dispatchOpenSearchBox();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onSlashKey);
    return () => window.removeEventListener('keyup', onSlashKey);
  }, []);

  return (
    <Root>
      {/* <div className="small-search">
        <IconButton onClick={() => dispatchOpenSearchBox()} modifiers={['noShadow']} ariaLabel="Open search box">
          <SearchIcon />
        </IconButton>
      </div> */}
      <div className="large-search">
        <Button
          modifiers={['contained', 'with-right-icon', 'small']}
          type="button"
          onClick={() => {
            dispatchOpenSearchBox();
          }}
        >
          Menu
          <SearchIcon />
        </Button>
      </div>

      <AnimatePresence>
        {showSearchBox && (
          <Modal
            open
            aria-labelledby="modal for search box"
            aria-describedby="modal for search box"
            disableRestoreFocus
          >
            <SearchBoxContainer aria-hidden="true">
              <Scrim onClick={() => dispatchCloseSearchBox()} />
              <SearchBox dispatchCloseSearchBox={dispatchCloseSearchBox} />
            </SearchBoxContainer>
          </Modal>
        )}
      </AnimatePresence>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  showSearchBox: state.search.showSearchBox,
});

const mapDispatchToProps = {
  dispatchOpenSearchBox: openSearchBox,
  dispatchCloseSearchBox: closeSearchBox,
};

export const Search = connect(mapStateToProps, mapDispatchToProps)(Component);

Component.propTypes = {
  showSearchBox: PropTypes.bool.isRequired,
  dispatchOpenSearchBox: PropTypes.func.isRequired,
  dispatchCloseSearchBox: PropTypes.func.isRequired,
};
