/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useCombobox } from 'downshift';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useSearch } from './useSearch';
import { SearchIcon } from '../Svg/SearchIcon';
import { CloseIcon } from '../Svg/CloseIcon';
import { slugify } from '../../utils';
import { IconButton } from '../Elements';

const SearchAnimation = {
  hidden: {
    opacity: 0,
    translateX: '-50%',
    scale: 0.92,
  },
  open: {
    opacity: 1,
    translateX: '-50%',
    scale: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.22,
    },
  },
  exit: {
    opacity: 0,
    translateX: '-50%',
    scale: 0.92,
    transition: {
      ease: 'easeInOut',
      duration: 0.15,
    },
  },
};

const Search = styled(motion.div)`
  position: absolute;
  top: 1rem;
  left: 50%;
  z-index: 3;
  width: calc(100% - 2rem);
  @media (min-width: 37rem) {
    width: 35rem;
  }
  box-shadow: ${({ theme }) => theme.elevation04};
  overflow: hidden;
  height: 3rem;
  color: ${({ theme }) => theme.onLightColorHighEmphasis};
  border: 1px solid currentColor;
  border-radius: ${({ theme }) => theme.borderRadius};
  svg {
    fill: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .input__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 3rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.defaultSurfaceE04};
    overflow: hidden;
    .search-icon {
      margin-left: 0.75rem;
    }
  }

  .input__inputbox {
    margin-left: 0.5rem;
    margin-right: 1rem;
    height: 100%;
    width: 100%;
    border: none;
    background-color: ${({ theme }) => theme.defaultSurfaceE04};
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    line-height: 1;
    padding-bottom: 0.25rem;
  }
`;

const List = styled(motion.ul)`
  position: absolute;
  left: 50%;
  top: 3.5rem;
  transform: translate3d(-50%, 0, 0);
  z-index: 3;
  width: calc(100% - 2rem);
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${(props) => (props.isOpen ? props.theme.defaultSurfaceE04 : 'transparent')};
  box-shadow: ${(props) => (props.isOpen ? props.theme.elevation04 : 'none')};
  border: ${(props) => (props.isOpen ? '1px solid currentColor' : 'none')};
  color: ${({ theme }) => theme.onLightColorHighEmphasis};
  @media (min-width: 37rem) {
    width: 35rem;
  }
  list-style: none;
  padding-left: 0;
  max-height: calc(100vh - 9rem);
  overflow-y: auto;

  li {
    border-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;
    position: relative;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    justify-content: space-between;
  }

  a {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    text-decoration: none;

    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.onLightDivider};
    }
  }
`;

const Result = styled.div`
  height: 4.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  li {
    > .state-overlay {
      background-color: ${({ theme }) => theme.primaryColor};
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: 0.26s;
      pointer-events: none;
      border-radius: inherit;
    }

    &:hover {
      cursor: pointer;
      > .state-overlay {
        opacity: 0.15;
      }
    }
  }

  .result__item {
    width: calc(100% - 4rem);
    overflow: hidden;
    white-space: nowrap;
  }

  .result__name {
    color: ${({ theme }) => theme.primaryColor};
  }

  .result__description {
    display: block;
    max-width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .result__price {
    align-self: flex-start;
    line-height: 1;
  }
`;

const NoResult = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchBox = ({ dispatchCloseSearchBox }) => {
  //
  const [inputItems, setInputItems] = useState([]);
  const [value] = useState();
  const search = useSearch();

  const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;

    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        // onSelect, close the modal, reset the state and navigate
        if (changes.selectedItem) {
          navigate(`/menu/${slugify(changes.selectedItem.item.category)}`, {
            state: { itemId: changes.selectedItem.item.slug },
          });
        }
        return {
          ...changes,
          isOpen: false,
          selectedItem: null,
          inputValue: '',
        };
      default:
        return changes;
    }
  };

  const { isOpen, getLabelProps, getMenuProps, getInputProps, getComboboxProps, getItemProps } = useCombobox({
    selectedItem: value,
    defaultHighlightedIndex: 0,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      if (typeof inputValue === 'string') {
        const results = search(inputValue);
        if (Array.isArray(results)) {
          setInputItems(results);
        }
      }
    },
    stateReducer,
  });

  const money = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  });

  return (
    <>
      <Search initial="hidden" animate="open" exit="exit" variants={SearchAnimation}>
        <div {...getComboboxProps()} className="input__container">
          <label {...getLabelProps()} hidden>
            Search
          </label>
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input
            type="text"
            {...getInputProps()}
            value={value}
            className="input__inputbox roboto-text-base roboto-text-base--body"
            placeholder="Search our menu"
            autoFocus
          />
          <div className="close-icon">
            <IconButton ariaLabel="close serach box" onClick={() => dispatchCloseSearchBox()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Search>
      <List {...getMenuProps()} isOpen={isOpen}>
        {isOpen &&
          inputItems.map((item, index) => {
            return (
              <Result {...getItemProps({ key: item.item.id, item: item.item, index })}>
                <li className="input__inputbox">
                  <div className="result__item">
                    <div className="roboto-text-base roboto-text-base--body result__name">{item.item.name}</div>
                    <div className="roboto-text-xs roboto-text-xs--caption result__description">
                      {item.item.description}
                    </div>
                  </div>
                  {/* <div className="roboto-text-base roboto-text-base--body result__price">
                    {money.format(item.item.variantPrice)}
                  </div> */}
                  <div className="state-overlay" />
                </li>
              </Result>
            );
          })}
        {isOpen && inputItems.length === 0 ? (
          <NoResult>
            <li className="input__inputbox roboto-text-base roboto-text-base--body">No results found</li>
          </NoResult>
        ) : null}
      </List>
    </>
  );
};

SearchBox.propTypes = {
  dispatchCloseSearchBox: PropTypes.func.isRequired,
};
