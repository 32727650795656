/* eslint-disable import/prefer-default-export */
import { useStaticQuery, graphql } from 'gatsby';
import Fuse from 'fuse.js';

const Query = graphql`
  query {
    menu: allSanityMenu {
      edges {
        ...allMenu
      }
    }
  }
`;

export const useSearch = () => {
  const data = useStaticQuery(Query);

  const {
    menu: { edges: menuData },
  } = data;

  const flatMenuData = menuData.map((item) => {
    return {
      name: item.node.name,
      description: item.node.description,
      category: item.node.menuMainCategory.menuMainCategoryName,
      subCategory: item.node.menuSubCategory && item.node.menuSubCategory.menuSubCategoryName,
      variantPrice: item.node.variants[0].variantPrice,
      id: item.node.id,
      slug: item.node.slug.current,
    };
  });

  const menuFuse = new Fuse(flatMenuData, {
    keys: [
      {
        name: 'name',
        weight: 2,
      },
      {
        name: 'description',
        weight: 2,
      },
      {
        name: 'category',
        weight: 2,
      },
      {
        name: 'subCategory',
        weight: 2,
      },
    ],
    distance: 500,
    includeMatches: true,
    includeScore: true,
    minMatchCharLength: 3,
    threshold: 0.3,
  });

  const search = (query) => {
    const results = menuFuse.search(query);
    return results;
  };
  return search;
};
