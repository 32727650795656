/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const Modifiers = {
  large: () => css`
    max-width: 90.5rem;
  `,
  full: () => css`
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  `,
};

export const Container = styled.div`
  max-width: 81.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  position: relative;

  ${applyStyleModifiers(Modifiers)};
`;
