/* eslint-disable import/prefer-default-export */
export const elevations = {
  elevation01: `rgba(0, 0, 0, 0.2) 0 2px 1px -1px, rgba(0, 0, 0, 0.14) 0 1px 1px 0, rgba(0, 0, 0, 0.12) 0 1px 3px 0`,
  elevation02: `rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 1px 5px 0`,
  elevation03: `rgba(0, 0, 0, 0.2) 0 3px 3px -2px, rgba(0, 0, 0, 0.14) 0 3px 4px 0, rgba(0, 0, 0, 0.12) 0 1px 8px 0`,
  elevation04: `rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0`,
  elevation05: `rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 5px 8px 0, rgba(0, 0, 0, 0.12) 0 1px 14px 0`,
  elevation06: `rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0`,
  elevation07: `rgba(0, 0, 0, 0.2) 0 4px 5px -2px, rgba(0, 0, 0, 0.14) 0 7px 10px 1px, rgba(0, 0, 0, 0.12) 0 2px 16px 1px`,
  elevation08: `rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px`,
  elevation09: `rgba(0, 0, 0, 0.2) 0 5px 6px -3px, rgba(0, 0, 0, 0.14) 0 9px 12px 1px, rgba(0, 0, 0, 0.12) 0 3px 16px 2px`,
  elevation10: `rgba(0, 0, 0, 0.2) 0 6px 6px -3px, rgba(0, 0, 0, 0.14) 0 10px 14px 1px, rgba(0, 0, 0, 0.12) 0 4px 18px 3px`,
  elevation11: `rgba(0, 0, 0, 0.2) 0 6px 7px -4px, rgba(0, 0, 0, 0.14) 0 11px 15px 1px, rgba(0, 0, 0, 0.12) 0 4px 20px 3px`,
  elevation12: `rgba(0, 0, 0, 0.2) 0 7px 8px -4px, rgba(0, 0, 0, 0.14) 0 12px 17px 2px, rgba(0, 0, 0, 0.12) 0 5px 22px 4px`,
  elevation13: `rgba(0, 0, 0, 0.2) 0 7px 8px -4px, rgba(0, 0, 0, 0.14) 0 13px 19px 2px, rgba(0, 0, 0, 0.12) 0 5px 24px 4px`,
  elevation14: `rgba(0, 0, 0, 0.2) 0 7px 9px -4px, rgba(0, 0, 0, 0.14) 0 14px 21px 2px, rgba(0, 0, 0, 0.12) 0 5px 26px 4px`,
  elevation15: `rgba(0, 0, 0, 0.2) 0 8px 9px -5px, rgba(0, 0, 0, 0.14) 0 15px 22px 2px, rgba(0, 0, 0, 0.12) 0 6px 28px 5px`,
  elevation16: `rgba(0, 0, 0, 0.2) 0 8px 10px -5px, rgba(0, 0, 0, 0.14) 0 16px 24px 2px, rgba(0, 0, 0, 0.12) 0 6px 30px 5px`,
  elevation17: `rgba(0, 0, 0, 0.2) 0 8px 11px -5px, rgba(0, 0, 0, 0.14) 0 17px 26px 2px, rgba(0, 0, 0, 0.12) 0 6px 32px 5px`,
  elevation18: `rgba(0, 0, 0, 0.2) 0 9px 11px -5px, rgba(0, 0, 0, 0.14) 0 18px 28px 2px, rgba(0, 0, 0, 0.12) 0 7px 34px 6px`,
  elevation19: `rgba(0, 0, 0, 0.2) 0 9px 12px -6px, rgba(0, 0, 0, 0.14) 0 19px 29px 2px, rgba(0, 0, 0, 0.12) 0 7px 36px 6px`,
  elevation20: `rgba(0, 0, 0, 0.2) 0 10px 13px -6px, rgba(0, 0, 0, 0.14) 0 20px 31px 3px, rgba(0, 0, 0, 0.12) 0 8px 38px 7px`,
  elevation21: `rgba(0, 0, 0, 0.2) 0 10px 13px -6px, rgba(0, 0, 0, 0.14) 0 21px 33px 3px, rgba(0, 0, 0, 0.12) 0 8px 40px 7px`,
  elevation22: `rgba(0, 0, 0, 0.2) 0 10px 14px -6px, rgba(0, 0, 0, 0.14) 0 22px 35px 3px, rgba(0, 0, 0, 0.12) 0 8px 42px 7px`,
  elevation23: `rgba(0, 0, 0, 0.2) 0 11px 14px -7px, rgba(0, 0, 0, 0.14) 0 23px 36px 3px, rgba(0, 0, 0, 0.12) 0 9px 44px 8px`,
  elevation24: `rgba(0, 0, 0, 0.2) 0 11px 15px -7px, rgba(0, 0, 0, 0.14) 0 24px 38px 3px, rgba(0, 0, 0, 0.12) 0 9px 46px 8px`,
};
