/* eslint-disable import/prefer-default-export */

export const localStorageRead = (key, defaultValue) => {
  const data = window.localStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return defaultValue;
    }
  }
  return defaultValue;
};
