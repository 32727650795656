/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import { mobileMenuSlice } from './mobileMenu';
import { announcementSlice } from './announcement';
import { drawerSlice } from './drawer';
import { themeSlice } from './theme';
import { searchSlice } from './search';

const reducer = {
  theme: themeSlice.reducer,
  mobileMenu: mobileMenuSlice.reducer,
  announcement: announcementSlice.reducer,
  drawer: drawerSlice.reducer,
  search: searchSlice.reducer,
};

export const store = configureStore({
  reducer,
});
