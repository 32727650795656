/* eslint-disable react/jsx-props-no-spreading */
/*  eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DatePicker = styled(({ ...rest }) => <KeyboardDatePicker classes={{ root: 'root' }} {...rest} />)`
  .MuiOutlinedInput-root {
    width: 10.75rem;

    &:hover fieldset {
      border-color: ${({ theme }) => theme.outlineColorHover};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.outlineColorFocused};
    }
  }

  .MuiOutlinedInput-root.Mui-error {
    &:hover fieldset {
      border-color: ${({ theme }) => theme.errorColor};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.errorColor};
    }
  }
`;
