/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';

const Modifiers = {
  small: () => css`
    height: 2rem;
    width: 2rem;
  `,

  noShadow: () => css`
    :hover,
    :focus {
      .background-scrim {
        opacity: 0;
      }
    }
  `,
};

const Root = styled.button`
  background-color: transparent;
  background-image: none;
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  height: ${({ theme }) => theme.heightDefault};
  width: ${({ theme }) => theme.heightDefault};
  border: none;

  svg {
    pointer-events: none;
    fill: ${(props) => props.theme.onLightColorHighEmphasis};
  }

  .background-scrim {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: currentColor;
    opacity: 0;
    transition: 0.26s;
    border-radius: 50%;
  }

  :hover {
    .background-scrim {
      opacity: 0.07;
    }
  }
  :focus {
    .background-scrim {
      opacity: 0.09;
    }
  }

  ${applyStyleModifiers(Modifiers)};
`;

export const IconButton = ({ modifiers, type, onClick, children, ariaLabel }) => {
  //
  return (
    <Root modifiers={modifiers} type={type} onClick={onClick} aria-label={ariaLabel}>
      <div className="background-scrim" />
      {children}
    </Root>
  );
};

IconButton.propTypes = {
  modifiers: styleModifierPropTypes(Modifiers),
  type: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

IconButton.defaultProps = {
  type: 'button',
  modifiers: () => {},
};
