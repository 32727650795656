/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const searchInitialState = {
  showSearchBox: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: searchInitialState,
  reducers: {
    openSearchBox: (state) => {
      state.showSearchBox = true;
    },
    closeSearchBox: (state) => {
      state.showSearchBox = false;
    },
  },
});

export const { openSearchBox } = searchSlice.actions;
export const { closeSearchBox } = searchSlice.actions;
