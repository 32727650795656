export const consideredScrolled = 100;

const commonStyles = {
  position: 'relative',
  animation: 'none',
  elevation: 'none',
};

export const defaultStyles = {
  navbarStyle: 'static',
};

export const scrolledFromTopStyles = {
  position: 'fixed',
  animation: '0.4s nav-show cubic-bezier(0.165, 0.84, 0.44, 1) forwards',
  elevation: 'rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0',
};

export const animatedHiddenStyles = {
  position: 'fixed',
  animation: '0.4s nav-hide cubic-bezier(0.165, 0.84, 0.44, 1) forwards',
};

export const pagesWithStyles = [
  {
    path: '/',
    ...commonStyles,
    navbarStyle: 'hide',
    // menuHeight: '6rem',
    // logoHeight: '2.75rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    bottomMargin: '-96px',
    linkColor: '#ffffff',
    iconFillColor: '#ffffff',
  },
  {
    path: '/menu',
    ...commonStyles,
    position: 'fixed',
    elevation: 'rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0',
    spacerHeight: '5rem',
    isMastheadDisabled: true,
  },
  {
    path: '/about-us',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/book',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/contact',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/reviews',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/promotions',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/sitemap',
    ...commonStyles,
    navbarStyle: 'hide',
  },
  {
    path: '/cookie-policy',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/privacy-policy',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
  {
    path: '/terms-of-use',
    ...commonStyles,
    navbarStyle: 'fixed',
  },
];
