/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const drawerInitialState = {
  showDrawer: false,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: drawerInitialState,
  reducers: {
    openDrawer: (state) => {
      state.showDrawer = true;
    },
    closeDrawer: (state) => {
      state.showDrawer = false;
    },
  },
});

export const { openDrawer } = drawerSlice.actions;
export const { closeDrawer } = drawerSlice.actions;
