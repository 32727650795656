/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import '../styles/scss/index.scss';

export const wrapPageElement = ({ element, props }) => {
  //
  const {
    uri,
    location,
    pageContext: {
      i18n: { originalPath },
    },
  } = props;

  return (
    <>
      <Layout
        location={location}
        uri={uri}
        path={`/${uri.split('/')[1]}`}
        originalPath={`/${originalPath.split('/')[1]}`}
      >
        <Helmet>
          <meta name="google-site-verification" content={process.env.GOOGLE_SITE_VERIFICATION} />
        </Helmet>
        <>{element}</>
      </Layout>
    </>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.object.isRequired,
};
