/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { window } from 'browser-monads';
import { localStorageWrite, localStorageRead } from '../utils';

export const getAnnouncement = createAsyncThunk('announcement/getAnnouncement', async () => {
  try {
    const res = await fetch('https://wd9xb4dg.apicdn.sanity.io/v1/graphql/production/default', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query {
          allAnnouncement {
            heading
            message
            show
            isDismissable
            hideDuration
          }
        }
      `,
      }),
    });
    const { data } = await res.json();

    const result = {
      heading: data.allAnnouncement[0].heading,
      message: data.allAnnouncement[0].message,
      hideDuration: data.allAnnouncement[0].hideDuration,
      show: data.allAnnouncement[0].show,
      showMasthead: false,
      showBanner: false,
      isDismissable: data.allAnnouncement[0].isDismissable,
    };

    if (window.innerWidth >= 640 && data.allAnnouncement[0].show) {
      result.showMasthead = true;
    }

    if (window.innerWidth < 640 && data.allAnnouncement[0].show) {
      result.showBanner = true;
    }

    const localState = localStorageRead('announcement', null);
    if (localState) {
      if (localState.message !== result.message && result.show === true) {
        return result;
      }
      if (localState.message === result.message && result.show === true) {
        if (Date.now() > localState.expiry) {
          return result;
        }
        return {
          ...result,
          showMasthead: false,
          showBanner: false,
        };
      }
    }
    return result;
  } catch (error) {
    return {
      heading: '',
      message: '',
      hideDuration: 0,
      show: false,
      showMasthead: false,
      showBanner: false,
      isDismissable: true,
    };
  }
});

const announcementInitialState = {
  heading: '',
  message: '',
  hideDuration: 0,
  show: false,
  showMasthead: false,
  showBanner: false,
  isDismissable: true,
};

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState: announcementInitialState,
  reducers: {
    showMasthead: (state, { payload }) => {
      state.heading = payload.heading;
      state.message = payload.message;
      state.hideDuration = payload.hideDuration;
      state.showMasthead = true;
      state.showBanner = false;
      state.isDismissable = payload.isDismissable;
    },
    closeMasthead: (state) => {
      state.showMasthead = false;
      const data = {
        // expiry: Date.now() + 1000 * 60 * 60 * 24 * state.hideDuration,
        expiry: Date.now() + state.hideDuration,
        message: state.message,
      };
      localStorageWrite('announcement', data);
    },
    showBanner: (state, { payload }) => {
      state.heading = payload.heading;
      state.message = payload.message;
      state.hideDuration = payload.hideDuration;
      state.showMasthead = false;
      state.showBanner = true;
      state.isDismissable = payload.isDismissable;
    },
    closeBanner: (state) => {
      state.showBanner = false;
      const data = {
        // expiry: Date.now() + 1000 * 60 * 60 * 24 * state.hideDuration,
        expiry: Date.now() + state.hideDuration,
        message: state.message,
      };
      localStorageWrite('announcement', data);
    },
  },
  extraReducers: {
    [getAnnouncement.fulfilled]: (state, action) => {
      state.heading = action.payload.heading;
      state.message = action.payload.message;
      state.hideDuration = action.payload.hideDuration;
      state.show = action.payload.show;
      state.showMasthead = action.payload.showMasthead;
      state.showBanner = action.payload.showBanner;
      state.isDismissable = action.payload.isDismissable;
    },
  },
});

export const { showMasthead } = announcementSlice.actions;
export const { closeMasthead } = announcementSlice.actions;
export const { showBanner } = announcementSlice.actions;
export const { closeBanner } = announcementSlice.actions;
