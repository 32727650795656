/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

// redux
import { store } from '../redux/store';

export const wrapRootElement = ({ element }) => {
  //
  return (
    <>
      <Provider store={store}>{element}</Provider>
    </>
  );
};

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
